import axios from 'axios';
import { History } from 'history';
import { API_ENDPOINT } from '../constants';
import { setAlert } from './alert';
import { GET_BRANDS, BRAND_FAIL, UPDATE_BRAND, ADD_BRAND, SET_BRAND_NAME, SET_TARGET_GROUP, REMOVE_CAMPAIGN, SET_BRAND_CLIENT_ID, SELECT_BRAND, GET_BRAND_FOR_EDIT } from './types';
import { Brand } from '../types';
import { AppThunk } from '../store';
import { resetTGAction } from './dsr';

interface GetBrandsAction {
  type: typeof GET_BRANDS,
  brands: Brand[]
}

interface AddBrandAction {
  type: typeof ADD_BRAND,
  brand: Brand
}

interface UpdateBrandAction {
  type: typeof UPDATE_BRAND,
  brand: Brand
}

interface SetBrandNameAction {
  type: typeof SET_BRAND_NAME,
  name: string
}

interface SetBrandClientIdAction {
  type: typeof SET_BRAND_CLIENT_ID,
  id: number
}

interface SetTargetGroupAction {
  type: typeof SET_TARGET_GROUP,
  tg: string
}

interface SelectBrandAction {
  type: typeof SELECT_BRAND,
  payload: { checked: boolean, brand_id: number }
}

interface GetBrandForEditAction {
  type: typeof GET_BRAND_FOR_EDIT,
  brand_id: number
}

interface BrandFailAction {
  type: typeof BRAND_FAIL
}

export type BrandActionTypes = GetBrandsAction | AddBrandAction | UpdateBrandAction | SetBrandNameAction | SetBrandClientIdAction | SetTargetGroupAction | SelectBrandAction | GetBrandForEditAction | BrandFailAction;

const getBrandsAction = (brands: Brand[]): BrandActionTypes => {
  return ({
    type: GET_BRANDS,
    brands
  })
}

const addBrandAction = (brand: Brand): BrandActionTypes => {
  return ({
    type: ADD_BRAND,
    brand
  })
}

const updateBrandAction = (brand: Brand): BrandActionTypes => {
  return ({
    type: UPDATE_BRAND,
    brand
  })
}

export const getBrandForEditAction = (brand_id: number): BrandActionTypes => {
  return ({
    type: GET_BRAND_FOR_EDIT,
    brand_id
  })
}

const brandFailAction = (): BrandActionTypes => {
  return ({
    type: BRAND_FAIL
  })
}

export const getBrands = (clients: string): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/brands/index`, {
      params: { clients }
    });
    if (res.data.status) {
      dispatch(getBrandsAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
      dispatch(brandFailAction());
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const addBrand = (brand: Brand, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/brands/create`, brand, config);

    if (res.data.status) {
      dispatch(addBrandAction(res.data.message));
      dispatch(setAlert('New brand added', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(brandFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch({ type: BRAND_FAIL });
  }
};

export const updateBrand = (brand: Brand, history: History, tgOnly: boolean): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/brands/update`, brand, config);

    if (res.data.status) {
      dispatch(updateBrandAction(res.data.message));
      dispatch(setAlert('Brand updated', 'success'));
      if (tgOnly) {
        dispatch(resetTGAction(true));
        history.push(`/campaign/${brand.brand_id}`)
      } else {
        history.goBack();
      }
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(brandFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(brandFailAction());
  }
};

export const setBrandNameAction = (name: string): BrandActionTypes => {
  return ({
    type: SET_BRAND_NAME,
    name
  })
};

export const setBrandClientIdAction = (id: number): BrandActionTypes => {
  return ({
    type: SET_BRAND_CLIENT_ID,
    id
  })
};

export const setTargetGroupAction = (target_group: string): BrandActionTypes => {
  return ({
    type: SET_TARGET_GROUP,
    tg: target_group
  })
};

export const selectBrand = (checked: boolean, brand_id: number) => async (dispatch: any) => {
  dispatch({
    type: SELECT_BRAND,
    payload: { checked, brand_id }
  });
  dispatch({
    type: REMOVE_CAMPAIGN,
    brand_id
  });
};